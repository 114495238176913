import React from "react";
import {Helmet} from "react-helmet-async";
import SubscribeComponent from "../components/SubscribeComponent";
import {useTranslation} from "react-i18next";

const Subscribe = () => {

    const application_type = process.env.REACT_APP_TYPE;

    const { t} = useTranslation();


    return (
        <>

            <Helmet>
                <title>{ application_type === 'b2b' ? 'Farming Weather' : 'Precision Weather Forecast'}</title>
            </Helmet>
            <div className={`page subscribe ${application_type}`}>
                <SubscribeComponent></SubscribeComponent>
            </div>
        </>

    )
}
export default Subscribe;