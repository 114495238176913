import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import stripePromise from './../stripe';
import Stripe from "../components/Stripe";
import Paypal from "../components/Paypal";
import {useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import BillingEmail from "../components/BillingEmail";
import ValidityDate from "../components/ValidityDate";
import PremiumAmount from "../components/PremiumAmount";
import {Helmet} from "react-helmet-async";
import {useTranslation} from "react-i18next";

const Premium = () => {

    const { email } = useParams();

    const emailSetByApp = useSelector((state) => state.email);

    let _email;
    if(email && !emailSetByApp) {
        _email = email;
    }
    if(!email && emailSetByApp) {
        _email = emailSetByApp;
    }
    if( email && emailSetByApp ) {
        _email = emailSetByApp;
    }

    const application_type = process.env.REACT_APP_TYPE;

    const { t } = useTranslation();


    return (
        <>
            <Helmet>
                <title>{ application_type === 'b2b' ? 'Farming Weather' : 'Precision Weather Forecast'}</title>
            </Helmet>

            <div className="page">
                { _email ? (<>
                <ValidityDate></ValidityDate>
                <PremiumAmount></PremiumAmount>
                <div className="premium-stripe-container">
                    <div className="premium-stripe">
                        <Elements stripe={stripePromise}>
                            <Stripe email={_email}></Stripe>
                        </Elements>
                    </div>
                </div>
                <div className="premium-paypal-container">
                    <div className="premium-paypal">
                        <Paypal email={_email}></Paypal>
                    </div>
                </div></>) : (<BillingEmail></BillingEmail>)}

            </div>
        </>)
}
export default Premium;