import React from "react";
import ChangePassword from "../components/ChangePassword";
import {Helmet} from "react-helmet-async";
import {useTranslation} from "react-i18next";

const SetPassword = () => {

    const application_type = process.env.REACT_APP_TYPE;

    const { t } = useTranslation();


    return (
        <>
            <Helmet>
                <title>{ application_type === 'b2b' ? 'Farming Weather' : 'Precision Weather Forecast'}</title>
            </Helmet>

            <div className="page">
                <ChangePassword></ChangePassword>
            </div>
        </>
    );
}
export default SetPassword