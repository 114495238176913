import Header from "../components/Header";
import {Helmet} from "react-helmet-async";
import React from "react";
import {useTranslation} from "react-i18next";

const NotFoundPage = () => {

    const application_type = process.env.REACT_APP_TYPE;

    const { t } = useTranslation();


    return (
        <>
            <Helmet>
                <title>{ application_type === 'b2b' ? 'Farming Weather' : 'Precision Weather Forecast'}</title>
            </Helmet>

            <div className="_404">
                <div className="info">
                    <h1>404</h1>
                    <p>Sorry, the page you are looking for does not exist.</p>
                    <a href="/">Go to Home</a>
                </div>
            </div>
        </>
    );
};

export default NotFoundPage;