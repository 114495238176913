import React from "react";

const Footer = () => {
    return (
        <div className="footer">
            <div className="container-copyright">
                <div className="copyright">copyright site 2024</div>
            </div>
        </div>)
}
export default Footer;