
import useQuery from "../tools/useQuery";
import {useEffect, useState} from "react";
import 'leaflet/dist/leaflet.css';
import React from 'react';
import {MapContainer, TileLayer, Marker, Popup, useMapEvents} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import {useTranslation} from "react-i18next";

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});
const LocationMarker = ({ setPosition }) => {
    const [position, setMarkerPosition] = useState(null);

    // Utilisation correcte de useMapEvents pour capturer les clics
    useMapEvents({
        click(e) {
            console.log(e);
            setMarkerPosition(e.latlng); // Met à jour la position du marqueur
            setPosition(e.latlng);       // Passe les coordonnées au parent
        },
    });

    return position === null ? null : (
        <Marker position={position}>
        </Marker>
    );
};
const LeafLet = ({setPosition}) => {

    const query = useQuery();
    const lat = query.get('lat') || 48.8566;
    const lng = query.get('lng') || 2.3522;

    const { t } = useTranslation();

     // Vue sur Paris


    const [selectedPosition, setSelectedPosition] = useState(null);
    useEffect(() => {
        setPosition(selectedPosition);
    },  [selectedPosition, setPosition]);


    return (
        <div>
            <h1>{ t('choose_on_map') }</h1>
            <MapContainer scrollWheelZoom={false} center={[lat, lng]} zoom={13} style={{ height: '500px', width: '100%' }}>
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />
                <LocationMarker setPosition={setSelectedPosition} />
            </MapContainer>
        </div>
    )

}
export default LeafLet;