import React from "react";
import {useParams} from "react-router-dom";
import GetForecast from "../components/GetForecast";
import {Helmet} from "react-helmet-async";
import {useTranslation} from "react-i18next";


const Graph = () => {

    const { lat, lng } = useParams();

    const { t } = useTranslation();


    const application_type = process.env.REACT_APP_TYPE;
    return (
        <>
            <Helmet>
                <title>{ application_type === 'b2b' ? 'Farming Weather' : 'Precision Weather Forecast'}</title>
            </Helmet>
            <div className="page large"><GetForecast lat={lat} lng={lng}></GetForecast></div>
        </>
    )
}
export default Graph;