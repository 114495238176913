import React from "react";
import QuestionForm from "../components/QuestionForm";
import {Helmet} from "react-helmet-async";
import {useTranslation} from "react-i18next";

const Contact = () => {

    const application_type = process.env.REACT_APP_TYPE;

    const { t } = useTranslation();


    return (
        <>
            <Helmet>
                <title>{ application_type === 'b2b' ? 'Farming Weather' : 'Precision Weather Forecast'}</title>
            </Helmet>

            <div className="page contact">
                <QuestionForm isCaptcha={true}></QuestionForm>
            </div>
        </>
    )
}
export default Contact;