import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet-async";
import React from "react";

const ContactYourSponsor = () => {

    const { t, i18n } = useTranslation();

    const application_type = process.env.REACT_APP_TYPE;

    return(<>
        <Helmet>
            <title>{ application_type === 'b2b' ? 'Farming Weather' : 'Precision Weather Forecast'}</title>
        </Helmet>

        <div className="page">{t('contact_your_sponsor')}</div>
    </>)
}
export default ContactYourSponsor;