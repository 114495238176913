import LeafLet from "../components/LeafLet";

const Map = () => {



    return (
        <><LeafLet></LeafLet></>
    )
}
export default Map