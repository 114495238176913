import useQuery from "../../tools/useQuery";
import {useEffect, useState} from "react";
import axiosInstance from "../../http/axios";
import usePaypalScript from "../../hook/usePaypalScript";
import {useNavigate, useParams} from "react-router-dom";
import {useSelector} from "react-redux";

const UserDetail = () => {

    const { id } = useParams();

    const [ user, setUser ] = useState({gender: null, firstname : null, lastname : null, email: null, id: null});
    const navigate = useNavigate();
    const lang = useSelector((state) => state.language);

    useEffect(() => {
        if(id) {
            axiosInstance.get('/api/admin/user/' + id).then((response) => {
                console.log(response.data);
                setUser(response.data);
            }).catch((err) => {
                console.log(err);
            })
        }
    }, [id])

    const deleteUser = (id) => {
        axiosInstance.delete('/api/admin/user/' + id).then((response) => {
            console.log(response.data);
            navigate('/' + lang + '/admin/users');
        }).catch((err) => {
            console.log(err);
        })
    }

    return(
        <>
            <div>
                <label>Gender</label>
                <div>{user.gender}</div>
            </div>
            <div>
                <label>Prénom</label>
                <div>{user.firstname}</div>
            </div>
            <div>
                <label>Nom de famille</label>
                <div>{user.lastname}</div>
            </div>
            <div>
                <label>Email</label>
                <div>{user.email}</div>
            </div>
            <div>
                <label>Supprimer</label>
                <div><button className="btn btn-danger" onClick={e => deleteUser(user.id)}>Supprimer</button> </div>
            </div>
        </>
    )
}
export default UserDetail;