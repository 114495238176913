import SetLanguage from "./SetLanguage";
import {useLocation, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import axiosInstance from "../http/axios";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import parseJwt from "../tools/parseJwt";
import useIsRouteSecure from "../hook/useIsRouteSecure";
import ApplicationTypeFilter from "./user/ApplicationTypeFilter";
import { useMediaQuery } from 'react-responsive';
import HeaderPicture from "./HeaderPicture";

const Header = () => {

    const application_type = process.env.REACT_APP_TYPE;

    const location = useLocation();

    const dispatch = useDispatch();

    const path = location.pathname;

    const navigate = useNavigate();

    const lang = useSelector((state) => state.language);
    const email = useSelector((state) => state.email);
    const error = useSelector((state) => state.error);

    const [isLogged, setIsLogged ] = useState( false );

    const [doPing , setDoPing ] = useState(0);
    const [isOpen , setIsOpen] = useState(false);
    const [hasUser , setHasUser ] = useState(false);

    const isSecure = useIsRouteSecure();

    const isMobile = useMediaQuery({ query: '(max-width: 750px) and (min-height: 400px)' });
    const isLandscape = useMediaQuery({ query: 'only screen and (min-device-width: 480px ) and (max-device-width: 940px) and (orientation: landscape)' });
    const isLandscapeSmall = useMediaQuery({query: 'only screen and (min-device-width: 480px) and (max-device-width: 700px) and (orientation: landscape)' });

    useEffect(() => {
        if(isLandscape) {
            setIsOpen(true);
        }
    }, [isLandscape]);
    const logOut = (e) => {
        window.localStorage.setItem('token', null);
        navigate('/' + lang);
        setDoPing(doPing + 1 );
    }

    const goHome = (e) => {
        navigate('/' + lang );
    }

    useEffect(() => {

        if(error) {
            const status = error?.response?.status;
            if(status >= 500) {
                navigate('/' + lang + '/error')
            }
            if(status === 401 || status === 403) {
                navigate('/' + lang + '/login');
                window.localStorage.setItem('token', null);
            }
            if(status === 402 && application_type === 'b2c') {
                navigate('/' + lang + '/premium');
            }

            if(status === 402 && application_type === 'b2b') {
                navigate('/' + lang + '/contact-your-sponsor');
            }
        }

    }, [error, lang])


    useEffect(() => {
        const token = window.localStorage.getItem('token');
        if(token) {
            const json = parseJwt(window.localStorage.getItem('token'));
            const email = json['email'];
            if(email) {
                setHasUser(true);
                dispatch({type: 'SET_EMAIL', payload: email});
            } else {
                setHasUser(false);
            }
        } else {
            setHasUser(false);
        }
    }, [dispatch, isLogged, doPing, isSecure])


    useEffect(() => {
        if ( isSecure === true) {
            axiosInstance.get('/api/ping').then((response) => {
                setIsLogged(true);

            }).catch((error) => {
                setIsLogged(false);
                navigate('/' + lang + '/login');
            });
        }
    }, [ doPing, isSecure])


    const [reload , setReload ] = useState(false);

    const { t } = useTranslation();

    useEffect(() => {
        if(path === '/') {
            setReload(true);
        } else {
            setReload(false);
        }
    }, [path])

    const toggleMenu = (event) => {
        let _isOpen = !!!isOpen
        setIsOpen(_isOpen);

    }



    const goLogin = (e) => {
        navigate('/' + lang + '/login');
    }

    const goSubscribe = (e) => {
        navigate('/' + lang + '/subscribe');
    }

    const goForgotPassword = (e) => {
        navigate('/' + lang + '/forgot-password');
    }

    const goContact = (e) => {
        navigate('/' + lang + '/contact');
    }

    const goBilling = (e) => {
        navigate('/' + lang + '/premium/' + (email??''));
    }

    const goCoordinate= (e) => {
        navigate('/' + lang + '/secure/coordinate');
    }

    const goUsers= (e) => {
        navigate('/' + lang + '/admin/users');
    }

    const goAmount= (e) => {
        navigate('/' + lang + '/admin/amount');
    }
    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light justify-content-between">
                <HeaderPicture></HeaderPicture>
                <div className="collapse navbar-collapse" id="navbarSupportedContent" style={{display: isOpen ? 'block' : 'none' }}>
                    <ul className="navbar-nav mr-auto">
                        {isLogged ? (<li className="nav-item active">
                                <a className="nav-link" onClick={e => logOut(e)}>{t('logout')}</a>
                            </li>
                        ) : <li className="nav-item active">
                            <a className="nav-link" onClick={goLogin}>{t('login')}</a>
                        </li>
                        }
                        <li className="nav-item">
                            <a className="nav-link" onClick={goSubscribe}>{t('subscribe')}</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" onClick={goForgotPassword}>{t('forgotten_password')}</a>
                        </li>

                        {application_type !== 'admin' && (<li className="nav-item">
                            <a className="nav-link" onClick={goContact}>{t('contact_us')}</a>
                        </li>
                        )}
                        {application_type === 'b2c' && hasUser && (<li className="nav-item">
                                <a className="nav-link" onClick={goBilling}>{t('billing')}</a>
                            </li>
                        )}
                        {application_type === 'b2c' && !hasUser && (<li className="nav-item">
                                <a className="nav-link disabled" >{t('billing')}</a>
                            </li>
                        )}

                        {application_type !== 'admin' && hasUser  && (<>
                            <li className="nav-item active">
                                <a className="nav-link" onClick={goCoordinate}>{t('coordinate')}</a>
                            </li>
                        </>)}
                        {application_type !== 'admin' && !hasUser  && (<>
                            <li className="nav-item active">
                                <a className="nav-link disabled">{t('coordinate')}</a>
                            </li>
                        </>)}

                        {application_type === 'admin' && hasUser && (<>
                            <li className="nav-item active">
                                <a className="nav-link" onClick={goUsers}>{t('users')}</a>
                            </li>
                        </>)}
                        {application_type === 'admin' && !hasUser  && (<>
                            <li className="nav-item active">
                                <a className="nav-link disabled">{t('users')}</a>
                            </li>
                        </>)}
                        {application_type === 'admin' && hasUser && (<>
                            <li className="nav-item active">
                                <a className="nav-link" onClick={goAmount}>{t('amount')}</a>
                            </li>
                        </>)}
                        {application_type === 'admin' && !hasUser  && (<>
                            <li className="nav-item active">
                                <a className="nav-link disabled">{t('amount')}</a>
                            </li>
                        </>)}
                    </ul>


            </div>
            <div className="form-inline my-2 my-lg-0" style={{'marginRight':"2em"}}>
                { application_type === 'admin' && (<ApplicationTypeFilter></ApplicationTypeFilter>)}
                { application_type !== 'admin' && (<SetLanguage reload={reload}></SetLanguage>)}
                { (isMobile || isLandscapeSmall ) && (<div className="menu-container" onClick={toggleMenu}>
                    <button className="menu-button" onClick={toggleMenu}>
                        <div className={`bar ${isOpen ? 'change' : ''}`}></div>
                        <div className={`bar ${isOpen ? 'change' : ''}`}></div>
                        <div className={`bar ${isOpen ? 'change' : ''}`}></div>
                    </button>
                </div>)}
            </div>
        </nav>
    );
};
export default Header;