import React, {useEffect, useState} from "react";
import axiosInstance from "../../http/axios";
import {useSelector} from "react-redux";
import ApplicationTypeFilter from "./ApplicationTypeFilter";
import UserFilterEmail from "./UserFilterEmail";
import UserFilterValid from "./UserFilterValid";
import UserFilterSoc from "./UserFilterSoc";
import UserFilterCountry from "./UserFilterCountry";
import UserApiLog from "./UserApiLog";
import UserFilterAlert from "./UserFilterAlert";
import UserAlert from "./UserAlert";
import {formatDateToReadable} from "../../tools/date";
import UserFilterDate from "./UserFilterDate";
import {useNavigate} from "react-router-dom";

const generateQuery = (query) => {
    return Object.keys(query).reduce(((acc, key, index) => {
        if(index === 0 ) {
            return acc + '?' + key + '=' + query[key];
        } else {
            return acc + '&' + key + '=' + query[key];
        }
    }), '');
}
const UserTab = () => {




    const [ users, setUsers ] = useState([]);

    const [ page, setPage ] = useState(1);
    const [ count, setCount ] = useState(0);
    const [ pages, setPages ] = useState(0);
    const [ _pages, set_pages ] = useState([]);
    const [reload, setReload ] = useState(0);

    const applicationType = useSelector((state) => state.applicationType);
    const email = useSelector((state) => state.adminEmail);
    const valid = useSelector((state) => state.valid);
    const soc = useSelector((state) => state.soc);
    const country = useSelector((state) => state.country);
    const alert = useSelector((state) => state.alert);
    const startOrder = useSelector((state) => state.startOrder);
    const endOrder = useSelector((state) => state.endOrder);
    const createdAtOrder = useSelector((state) => state.createdAtOrder);
    const lang = useSelector((state) => state.language);



    let test = 'null';
    console.log(Number(test));

    useEffect(() => {

        let query = {};
        if(page) {
            query['page'] = page;
        }
        if(applicationType) {
            query['applicationType'] = applicationType;
        }
        if(email) {
            query['email'] = email;
        }
        if(valid) {
            query['valid'] = valid;
        }
        if(soc) {
            query['soc'] = soc;
        }
        if(country) {
            query['country'] = country;
        }
        if(alert) {
            query['isAlert'] = alert;
        }
        if(startOrder) {
            query['startOrder'] = startOrder;
        }
        if(endOrder) {
            query['endOrder'] = endOrder;
        }
        if(createdAtOrder) {
            query['createdAtOrder'] = createdAtOrder;
        }




        axiosInstance.get('/api/admin/user' + generateQuery(query)).then((response) => {
            console.log(response.data.data);
            setUsers(response.data.data);
            setPage(response.data.currentPage);
            setPages(response.data.totalPages);
            setCount(response.data.totalRecords);
            let temp = [];
            for(var i=1;i<=response.data.totalPages; i++) {
                temp.push(i);
            }
            set_pages(temp);

        }).catch((error) => {
            console.log(error);
        })
    }, [page, applicationType,email, valid, soc, country, alert, reload , startOrder, endOrder, createdAtOrder]);

    const previous = () => {
        if(page>1) {
            setPage(page-1);
        }
    }
    const next = () => {
        if(page<pages) {
            setPage( page + 1);
        }
    }

    const navigate = (index) => {
        setPage(index);
    }

    const navigation = useNavigate();
    const getUser = (id) => {
        navigation('/' + lang + '/admin/user/' + id );
    }



    return (<>
        <h4>{count} enregistrement{count>1??'s'}</h4>
        <table className="table">
            <thead>
                <tr>
                    <th scope="col">Email</th>
                    <th scope="col">Creation</th>
                    <th scope="col">Valid</th>
                    <th scope="col">Code Société</th>
                    <th scope="col">Pays</th>
                    <th scope="col">Recherche/Mois</th>
                    <th scope="col">Nb Coordonnée</th>
                    <th scope="col">Date Début</th>
                    <th scope="col">Date Fin</th>
                    { applicationType === 'b2b' && (<th scope="col">Alerte</th>)}
                    <th scope="col">Detail</th>
                </tr>
                <tr>

                    <th scope="col"><UserFilterEmail></UserFilterEmail></th>
                    <th scope="col"><UserFilterDate type="createdAtOrder"></UserFilterDate></th>
                    <th scope="col"><UserFilterValid></UserFilterValid></th>
                    <th scope="col"><UserFilterSoc></UserFilterSoc></th>
                    <th scope="col"><UserFilterCountry></UserFilterCountry></th>
                    <th scope="col">&nbsp;</th>
                    <th scope="col">&nbsp;</th>
                    <th scope="col"><UserFilterDate type="startOrder"></UserFilterDate></th>
                    <th scope="col"><UserFilterDate type="endOrder"></UserFilterDate></th>
                    { applicationType === 'b2b' && (<th scope="col"><UserFilterAlert></UserFilterAlert></th>)}
                    <th scope="col">&nbsp;</th>
                </tr>
            </thead>
            <tbody>
            {users.map((user) => { return (
                <tr key={user.id}>
                    <th scope="row">{ user.email }</th>
                    <td>{ user.createdAt? formatDateToReadable(user.createdAt) : ''}</td>
                    <td>{user.valid ? 'validé' : 'non validé'}</td>
                    <td>{user.code_company}</td>
                    <td>{user.country}</td>
                    <td><UserApiLog data={user.api_log}></UserApiLog></td>
                    <td>{ user.coordinates.length }</td>
                    <td>{ user.date_start_premium? formatDateToReadable(user.date_start_premium) : ''}</td>
                    <td>{ user.date_end_premium? formatDateToReadable(user.date_end_premium) : ''}</td>
                    { applicationType === 'b2b' && (<td><UserAlert display={alert==='1'} user={user} reload={reload} setReload={setReload}></UserAlert></td>)}
                    <td><button className="btn btn-success" onClick={e => getUser(user.id)}>Navigate</button></td>
                </tr>
            )})}

            </tbody>
        </table>
        <nav aria-label="Page navigation example">
            <ul className="pagination">
                { page > 1 && (<li  className="page-item"><a className="page-link" href="#" onClick={e => previous()}>Précédent</a></li>)}
                { _pages.map((index) => (<li key={index} className="page-item"><a className="page-link" href="#" onClick={e=> navigate(index)}>{index}</a></li>))}
                {page < pages && (<li className="page-item"><a className="page-link" href="#" onClick={e => next()}>Suivant</a></li>)}
            </ul>
        </nav>
    </>);
};

export default UserTab;