import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import {useTranslation} from "react-i18next";

const ConfirmDeleteModal = ({ show, handleClose, handleDelete }) => {

    const { t } = useTranslation();

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{ t('confirm_delete_t')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{ t('confirm_delete') }</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    {t('cancel')}
                </Button>
                <Button variant="danger" onClick={handleDelete}>
                    {t('delete_s')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ConfirmDeleteModal;